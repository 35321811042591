import { template as template_daed63976c9342e2b4ce3fe39415b993 } from "@ember/template-compiler";
const FKText = template_daed63976c9342e2b4ce3fe39415b993(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
