import { template as template_bb12e4f9ba3c4d70a86f74a8ab72ab0e } from "@ember/template-compiler";
const FKLabel = template_bb12e4f9ba3c4d70a86f74a8ab72ab0e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
