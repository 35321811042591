import { template as template_3c54d1499a9c4920a2aed06ad03eedff } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_3c54d1499a9c4920a2aed06ad03eedff(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
